<template>
  <div class="bg">
    <div class="top mb8">
      <div class="top_top">
        <img src="../../../assets/img/inquiry_10.png" alt="" />
        <div>
          <h3>北京水滴互保科技有限公司</h3>
          <div class="right">
            <img src="../../../assets/img/inquiry_10.png" alt="" srcset="" />
            已救助 <span> 384,059,7</span>人
          </div>
        </div>
      </div>
      <div class="info sl">
        水滴筹致力于打造透明可信的个人大病救助平台，共有超过2.5亿名爱心人士参与帮助水滴筹致力于打造透明可信的个人大病救助平台
        水滴筹致力于打造透明可信的个人大病救助平台，共有超过2.5亿名爱心人士参与帮助水滴筹致力于打造透明可信的个人大病救助平台
        水滴筹致力于打造透明可信的个人大病救助平台，共有超过2.5亿名爱心人士参与帮助水滴筹致力于打造透明可信的个人大病救助平台
      </div>
    </div>
    <div class="bot mb8">
      <div class="bot_top">
        <div class="left">
          <img src="../../../assets/img/inquiry_1.png" alt="" srcset="" />
          <div>
            <h1>540,151,39</h1>
            <p>捐赠金额（元）</p>
          </div>
        </div>
        <div class="left">
          <img src="../../../assets/img/inquiry_1.png" alt="" srcset="" />
          <div>
            <h1>540,151,39</h1>
            <p>问诊总人数</p>
          </div>
        </div>
      </div>
      <div class="btnbox">
        <van-button
          round
          type="info"
          size="large"
          @click="gonav('applyfor')"
          color="rgba(29,174,252,1)"
          >申请救助</van-button
        >
      </div>
    </div>
    <div class="fot">
      <h1>已救助</h1>
      <div class="mylist mb8" v-for="item in 10" :key="item">
        <img src="../../../assets/img/inquiry_3.png" alt="" />
        <div>
          <p class="sl1">
            我还想在重返校园，叔叔阿姨大我还想在重返校园，叔叔阿姨大我还想在重返校园，叔叔阿姨大家帮帮
          </p>
          <p>筹款金额：<span>55555</span> 元</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    gonav(url) {
      this.$router.push({ name: url });
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  .top {
    // height: 113px;
    padding: 16px;
    background: #fff;
    text-align: left;

    font-size: 12px;
    .top_top {
      display: flex;
      margin-bottom: 16px;
    }
    h3 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    img {
      display: block;
      width: 60px;
      height: 60px;
      // border-radius: 50%;
      margin-right: 16px;
    }
    .right {
      h1 {
        font-size: 16px;
        color: rgba(45, 45, 45, 1);
        margin-bottom: 8px;
      }
      p {
        min-height: 57px;
        margin-bottom: 16px;
      }
      img {
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }
      span {
        color: rgba(251, 81, 81, 1);
      }
    }
  }
  .info {
    // padding: 0 16px;
    background: #fff;
    height: 56px;
    // padding-bottom: 16px;
  }
  .bot {
    padding: 16px;
    background: #fff;
    height: 173px;
    .bot_top {
      display: flex;
      .left {
        display: flex;
        width: 171px;
        border-right: 1px solid #efefef;
        margin-right: 12px;
        &:nth-child(2) {
          border: none;
          margin-right: 0;
        }
        h1 {
          color: rgba(29, 174, 252, 1);
          font-weight: 700;
          margin-bottom: 4px;
          font-size: 16px;
        }
        p {
          font-size: 12px;
        }
      }
      img {
        width: 50px;
        height: 50px;
        margin-right: 8px;
      }
    }
  }
  .btnbox {
    margin-top: 15px;
    padding: 24px;
  }
  .fot {
    padding: 16px;
    background: #fff;
    h1 {
      color: rgba(45, 45, 45, 1);
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 23px;
    }
    .mylist {
      display: flex;
      img {
        width: 80px;
        height: 60px;
        margin-right: 13px;
      }
      p {
        margin-bottom: 8px;
        span {
          color: rgba(29, 174, 252, 1);
        }
      }
    }
  }
}
</style>
